<template>
	<div :key="imageUploaderKey">
		<input
			ref="input"
			class="hidden"
			type="file"
			name="image"
			accept="image/*"
			@change="setImage"
		>
		<div class="flex justify-between">
			<section class="w-full p-2 border-4 border-gray-300 border-dashed rounded">
				<div
					class="relative flex items-center justify-center cursor-pointer preview-height"
					@click="showFileChooser"
				>
					<template v-if="imgSrc">
						<div class="absolute flex items-center justify-center w-full h-full">
							<div class="absolute w-full overflow-hidden preview preview-height" />
							<template v-if="showProgress">
								<div class="z-10 flex items-center justify-center w-full h-full bg-gray-500 opacity-70" />
								<div class="absolute z-10 flex items-center justify-center w-full h-full text-5xl font-bold tracking-widest text-white">
									{{ percentComplete }}%
								</div>
							</template>
						</div>
						<button
							class="absolute top-0 right-0 flex items-center justify-center w-8 h-8 -mt-6 -mr-6 font-bold text-white bg-red-500 rounded-full shadow-lg"
							@click.stop.prevent="removeImage"
						>
							<close-icon
								class="w-3 h-3"
							/>
						</button>
					</template>
					<div
						v-else
						class="font-bold"
					>
						Choose an image
					</div>
				</div>
				<vue-cropper
					ref="cropper"
					class="hidden"
					:src="imgSrc"
					:auto-crop="autoCrop"
					:auto-crop-area="autoCropArea"
					:aspect-ratio="aspectRatio"
					:initial-aspect-ratio="initialAspectRatio"
					preview=".preview"
				/>
			</section>
		</div>
	</div>
</template>

<script async>
import 'cropperjs/dist/cropper.css'

import axios from 'axios'
import VueCropper from 'vue-cropperjs'
import { mapMutations } from 'vuex'

import CloseIcon from '@/components/icons/CloseIcon.vue'
import { API_SERVER } from '@/constants/index.js'
import { ERROR } from '@/constants/toast/type.js'

export default {
	components: {
		CloseIcon,
		VueCropper
	},
	props: {
		autoCrop: { type: Boolean, default: false },
		autoCropArea: { type: Number, default: 1 },
		aspectRatio: { type: Number, default: NaN },
		initialAspectRatio: { type: Number, default: NaN },
		shouldReset: { type: Boolean, default: false }
	},
	emits: [ 'image-updated' ],
	data() {
		return {
			imageUploaderKey: 0,
			imgSrc: null,
			cropImg: '',
			data: null,
			showProgress: false,
			percentComplete: 0
		}
	},
	watch: {
		shouldReset(shouldReset) {
			if (shouldReset) {
				this.removeImage()
			}
		}
	},
	methods: {
		...mapMutations('toast', [ 'showToast' ]),
		reloadComponent() {
			this.imageUploaderKey += 1
		},
		removeImage() {
			this.imgSrc = null
			this.$refs.cropper.destroy()
			this.reloadComponent()
			this.$emit('image-removed')
		},
		async fileUpload(file) {
			const data = new FormData()
			data.append('aFile', file)
			const result = await axios(`${API_SERVER}/file_upload.php?ajax_post=true`, {
				method: 'post',
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				data,
				onUploadProgress: (progressEvent) => {
					if (progressEvent.lengthComputable) {
						this.showProgress = true
						this.percentComplete = parseInt(progressEvent.loaded / progressEvent.total * 100)
					}
				}
			})
			this.showProgress = false
			this.$emit('image-updated', result?.data?.name)
		},
		async setImage(e) {
			const file = e.target.files[0]
			if (file?.type.indexOf('image/') === -1) {
				this.showToast({
					primaryText: 'Error',
					secondaryText: 'Please select an image file',
					type: ERROR
				})
				return
			}

			if (typeof FileReader === 'function') {
				const reader = new FileReader()

				reader.onload = (event) => {
					this.imgSrc = event.target.result
					// rebuild cropperjs with the updated source
					this.$refs.cropper.replace(event.target.result)
				}

				reader.readAsDataURL(file)
				this.fileUpload(file)
			} else {
				this.showToast({
					primaryText: 'Error',
					secondaryText: 'Sorry, this browser is not supported',
					type: ERROR
				})
			}
		},
		showFileChooser() {
			if (!this.imgSrc) {
				this.$refs.input.click()
			}
		}
	}
}
</script>

<style scoped>
.preview-height {
	min-height: calc(372px * (9 / 16));
}
</style>
